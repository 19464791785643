import React from 'react';
import { string } from 'prop-types';
import { Box, AspectRatio, Text, Button, Center, Link, Heading, Image } from '@chakra-ui/react';

import {
  THE_BRIDE,
  YOUTUBE_EMBED,
  YOUTUBE_LINK,
  WEDDING_AKAD_TIME,
  WEDDING_RESEPSI_TIME,
  WEDDING_AKAD_FULLDATE,
  WEDDING_RESEPSI_FULLDATE,
  PREWEDDING_LINK,
  PREWEDDING_EMBED,
  YOUTUBE_AKAD_LINK,
  YOUTUBE_AKAD_EMBED,
} from '@/constants';
import { BUTTON_PADDING_PROPS, BUTTON_PROPS } from '@/constants/colors';

import WithAnimation from '@components/Common/WithAnimation';
import { ASSETS_FLOWER_LIVE_STREAMING } from '@/constants/assets';
import txt from './locales';

/**
 * @function YoutubeLiveSection
 * Render Live Streaming component
 * @returns {JSX.Element}
 */
function YoutubeLiveSection({ lang, ...rest }) {
  return (
    <Box position="relative" textAlign="center" {...rest}>
      {/* LIVE STREAMING SECTION */}
      <Box width="100%">
        <WithAnimation>
          <Heading
            fontWeight="normal"
            size="xl"
            textAlign="center"
            margin="0"
            color="secondaryColorText"
            fontFamily="CustomFont"
            marginBottom="24px"
          >
            Live Streaming
          </Heading>
        </WithAnimation>
        
        {/* Holy Mat */}
        <WithAnimation>
          <Heading
            fontWeight="normal"
            size="md"
            textAlign="center"
            margin="0"
            color="secondaryColorText"
            fontFamily="CustomFont"
            marginBottom="8px"
          >
            Holy Matrimony
          </Heading>
          <Text
            fontSize="md"
            textAlign="center"
            color="mainColorText"
            marginBottom="16px"
            dangerouslySetInnerHTML={{
              __html: `${WEDDING_AKAD_FULLDATE} | ${WEDDING_AKAD_TIME}`,
            }}
          />
        </WithAnimation>
        
        {/* Live stream Holy section */}
        <WithAnimation right>
          <Box pos="relative">
            <AspectRatio maxW="560px" ratio={16 / 9} boxShadow="xl">
              <iframe
                id="ytplayer"
                title={`Live wedding of ${THE_BRIDE}`}
                src={YOUTUBE_AKAD_EMBED}
                allowFullScreen
                style={{ borderRadius: '8px' }}
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </AspectRatio>
            <Image
              src={ASSETS_FLOWER_LIVE_STREAMING}
              height="140px"
              pos="absolute"
              top="-16px"
              right="-16px"
            />
            <Image
              src={ASSETS_FLOWER_LIVE_STREAMING}
              height="140px"
              pos="absolute"
              bottom="-24px"
              left="-16px"
              transform="scaleX(-1)"
            />
          </Box>
        </WithAnimation>
        <WithAnimation right>
          <Text
            textAlign="center"
            fontSize="md"
            color="mainColorText"
            margin="16px 0"
            padding="0 12px 0 12px"
          >
            {txt.problem[lang]}
          </Text>
        </WithAnimation>
        <WithAnimation left>
          <Center>
            <Link href={YOUTUBE_AKAD_LINK} target="_blank" _hover={{ textDecoration: 'none' }}>
              <Button
                size="xs"
                fontWeight="normal"
                bgColor="bgAlternative"
                marginBottom="16px"
                minW="140px"
                {...BUTTON_PROPS}
                {...BUTTON_PADDING_PROPS}
                fontSize="md"
              >
                Holy Matrimony
              </Button>
            </Link>
          </Center>
        </WithAnimation>

        {/* Reception */}
        <WithAnimation>
        <Heading
            fontWeight="normal"
            size="md"
            textAlign="center"
            margin="0"
            color="secondaryColorText"
            fontFamily="CustomFont"
            marginBottom="8px"
            marginTop="32px"
          >
            Reception
          </Heading>
          <Text
            fontSize="md"
            textAlign="center"
            color="mainColorText"
            marginBottom="16px"
            dangerouslySetInnerHTML={{
              __html: `${WEDDING_RESEPSI_FULLDATE} | ${WEDDING_RESEPSI_TIME}`,
            }}
          />
        </WithAnimation>
        
        {/* Live stream Reception section */}
        <WithAnimation right>
          <Box pos="relative">
            <AspectRatio maxW="560px" ratio={16 / 9} boxShadow="xl">
              <iframe
                id="ytplayer"
                title={`Live wedding of ${THE_BRIDE}`}
                src={YOUTUBE_EMBED}
                allowFullScreen
                style={{ borderRadius: '8px' }}
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </AspectRatio>
            <Image
              src={ASSETS_FLOWER_LIVE_STREAMING}
              height="140px"
              pos="absolute"
              top="-16px"
              right="-16px"
            />
            <Image
              src={ASSETS_FLOWER_LIVE_STREAMING}
              height="140px"
              pos="absolute"
              bottom="-24px"
              left="-16px"
              transform="scaleX(-1)"
            />
          </Box>
        </WithAnimation>
        <WithAnimation right>
          <Text
            textAlign="center"
            fontSize="md"
            color="mainColorText"
            margin="16px 0"
            padding="0 12px 0 12px"
          >
            {txt.problem[lang]}
          </Text>
        </WithAnimation>
        <WithAnimation left>
          <Center>
            <Link href={YOUTUBE_LINK} target="_blank" _hover={{ textDecoration: 'none' }}>
              <Button
                size="xs"
                fontWeight="normal"
                bgColor="bgAlternative"
                marginBottom="16px"
                minW="140px"
                {...BUTTON_PROPS}
                {...BUTTON_PADDING_PROPS}
                fontSize="md"
              >
                Reception
              </Button>
            </Link>
          </Center>
        </WithAnimation>

        {/* prewed section */}
        <WithAnimation>
          <Heading
            fontWeight="normal"
            size="xl"
            textAlign="center"
            margin="0"
            color="secondaryColorText"
            fontFamily="CustomFont"
            marginBottom="24px"
            marginTop="48px"
          >
            Pre Wedding
          </Heading>
        </WithAnimation>
        <WithAnimation right>
          <Box pos="relative">
            <AspectRatio maxW="560px" ratio={16 / 9} boxShadow="xl">
              <iframe
                id="ytplayer"
                title={`Live wedding of ${THE_BRIDE}`}
                src={PREWEDDING_EMBED}
                allowFullScreen
                style={{ borderRadius: '8px' }}
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </AspectRatio>
            <Image
              src={ASSETS_FLOWER_LIVE_STREAMING}
              height="140px"
              pos="absolute"
              top="-16px"
              right="-16px"
            />
            <Image
              src={ASSETS_FLOWER_LIVE_STREAMING}
              height="140px"
              pos="absolute"
              bottom="-24px"
              left="-16px"
              transform="scaleX(-1)"
            />
          </Box>
        </WithAnimation>
        <WithAnimation right>
          <Text
            textAlign="center"
            fontSize="md"
            color="mainColorText"
            margin="16px 0"
            padding="0 12px 0 12px"
          >
           Pre wedding video can also be watched via the YouTube platform, please click the button below:
          </Text>
        </WithAnimation>
        <WithAnimation left>
          <Center>
            <Link href={PREWEDDING_LINK} target="_blank" _hover={{ textDecoration: 'none' }}>
              <Button
                size="xs"
                fontWeight="normal"
                bgColor="bgAlternative"
                marginBottom="16px"
                minW="140px"
                {...BUTTON_PROPS}
                {...BUTTON_PADDING_PROPS}
                fontSize="md"
              >
                Pre Wedding
              </Button>
            </Link>
          </Center>
        </WithAnimation>
        
       
      </Box>
    </Box>
  );
}

YoutubeLiveSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(YoutubeLiveSection);
